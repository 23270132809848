import React, { useEffect } from 'react';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { format, parse, isValid } from 'date-fns';
import { navigate } from 'gatsby';
import { connect, ConnectedProps } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { IState, ContentSelectors } from '@pvolve/sdk/src/redux/selectors';
import { IWorkout } from '@pvolve/sdk/src/models/contentful_types';
import { WindowLocation } from '@reach/router';

import Selectors from 'src/state/root-selectors';
import Actions from 'src/state/root-actions';
import { CardGridWrapper, WorkoutCard } from 'src/components/shared';
import Layout from 'src/components/layout/Layout';

import * as Styles from 'src/styles/new-workouts.module.scss';

interface CompletedWorkoutsPageParams {
    date: string;
}

interface CompletedWorkoutsPageProps {
    params: CompletedWorkoutsPageParams;
    location: WindowLocation;
}

const mapStateToProps = (state: IState, { params: { date } }: CompletedWorkoutsPageProps) => {
    const parsedDate = parse(date, 'MM-dd-yyyy', new Date());
    const _date = isValid(parsedDate) ? parsedDate : new Date();
    const internalDateFormat = format(_date, 'yyyy-MM-dd');
    const index = ContentSelectors.workout.indexedBy.id(state);
    const completedHistoryByDay = PvolveSelectors.workouts.completedHistoryByDate(state);
    const completed = completedHistoryByDay[internalDateFormat] || [];

    return {
        entitled: Selectors.auth.entitled(state),
        workouts: completed.map((history) => index[history.workout_id]),
    };
};

const mapDispatchToProps = {
    loadHistory: () => Actions.workouts.loadHistory.trigger(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const CompletedWorkoutsPage = ({
    entitled,
    workouts,
    params,
    loadHistory,
    location,
}: CompletedWorkoutsPageProps & ConnectedProps<typeof connector>) => {
    const { date } = params;

    useEffect(() => {
        loadHistory();
    }, [loadHistory]);

    if (!date || !entitled) {
        navigate('/workouts');
        return null;
    }

    const parsedDate = parse(date, 'MM-dd-yyyy', new Date());
    const _date = isValid(parsedDate) ? parsedDate : new Date();
    const displayDate = format(_date, 'EEEE, M.dd.yy');
    const hasWorkouts = workouts?.length > 0;
    const pageTitle = hasWorkouts ? `Completed ${displayDate}` : 'No workouts found';
    const pageContext = {};

    return (
        <Layout pageContext={pageContext} location={location}>
            <div className={Styles.wrapper}>
                <Grid>
                    <Grid.Row className="page-section">
                        <Grid.Column>
                            <Grid container>
                                <Grid.Row verticalAlign="bottom" className="padding--0">
                                    <Grid.Column width={10} floated="left">
                                        <h2 className="upper bold">{pageTitle}</h2>
                                    </Grid.Column>
                                    {hasWorkouts && (
                                        <Grid.Column width={6} floated="right" textAlign="right">
                                            <span>
                                                {workouts.length} Workout
                                                {workouts.length > 1 ? 's' : ''}
                                            </span>
                                        </Grid.Column>
                                    )}
                                </Grid.Row>

                                {hasWorkouts && (
                                    <CardGridWrapper>
                                        {workouts.map((workout: IWorkout, index: number) => (
                                            <WorkoutCard
                                                useBackgroundImage
                                                key={`workout-card-${index}`}
                                                workout={workout}
                                            />
                                        ))}
                                    </CardGridWrapper>
                                )}
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Layout>
    );
};

export default connector(CompletedWorkoutsPage);
